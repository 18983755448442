import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, useHistory } from "react-router-dom"
import { isPageTop, share } from "../common/utils"
import { auth, logUserEvent } from "../common/firebase.utils"
import { ReactComponent as LogoImg } from "../images/logo_gnb.svg"
import { ReactComponent as CircleCloseImg } from "../images/closebtn.svg"
import { ReactComponent as CloseImg } from "../images/close.svg"
import { ReactComponent as MenuImg } from "../images/menu.svg"
import { ReactComponent as BackImg } from "../images/back.svg"
import { ReactComponent as BadgeImg } from "../images/badge-r-w.svg"
import ProfilePicture from "./ProfilePicture"
import { useUserInfo } from "../hooks/useUserInfo"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"
import { signOut } from "../store/slices/userSlice"

export const TYPE = {
  DEFAULT: "default",
  MEMBER: "member",
  CLOSE: "close",
  BACK: "back"
}

function Gnb({ clearOnTop, type }) {
  const BACKGROUND_CHANGE_OFFSET = 100

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800)
  const [onTop, setOnTop] = useState(isPageTop(BACKGROUND_CHANGE_OFFSET))
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const { isLoading, loggedIn, photo, nickname, authProvider, membership } =
    useUserInfo()
  const history = useHistory()
  const dispatch = useDispatch()
  const isMembership = membership === "member"

  const updateBackground = () => {
    setOnTop(isPageTop(BACKGROUND_CHANGE_OFFSET))
  }
  useEffect(() => {
    const resizeLisnener = () => {
      setIsMobile(window.innerWidth <= 800)
    }
    window.addEventListener("resize", resizeLisnener)
    if (clearOnTop) {
      window.addEventListener("scroll", updateBackground)
    }
    return () => {
      window.removeEventListener("resize", resizeLisnener)
      if (clearOnTop) {
        window.removeEventListener("scroll", updateBackground)
      }
    }
  }, [clearOnTop])

  const goBack = () => {
    history.goBack()
  }

  const onClickLogout = () => {
    if (authProvider === "naver") {
      const naverLogin = new window.naver.LoginWithNaverId({
        clientId: "cL2yhpIHZyIQyueb0oDk",
        callbackUrl: "https://whattosay.kr/login?with=naver"
      })
      naverLogin.init()
      naverLogin.logout()
    } else {
      auth.signOut()
    }
    alert("로그아웃 되었습니다.")
    dispatch(signOut())
  }

  return (
    <Container clear={clearOnTop ? onTop : false}>
      <LeftMenus type={type} isMobile={isMobile} goBack={goBack} />
      <div id="logo">
        <Link to="/">
          <LogoImg
            className="clickable"
            width={window.innerWidth <= 800 ? 127 : 138}
          />
        </Link>
      </div>
      <WrightMenus
        type={type}
        isLoading={isLoading}
        loggedIn={loggedIn}
        isMembership={isMembership}
        onClickLogout={onClickLogout}
        isMobile={isMobile}
        showMobileMenu={() => setShowMobileMenu(true)}
        oBack={goBack}
      />
      {showMobileMenu && (
        <MobileMenu
          isLoading={isLoading}
          loggedIn={loggedIn}
          isMembership={isMembership}
          photo={photo}
          nickname={nickname}
          onClickLogout={onClickLogout}
          hideMenu={() => setShowMobileMenu(false)}
        />
      )}
    </Container>
  )
}

function LeftMenus({ type, goBack, isMobile }) {
  if (isMobile) {
    if (type === TYPE.BACK) {
      return (
        <div id="gnb__left">
          <BackImg onClick={goBack} />
        </div>
      )
    }
    return <div id="gnb__left" />
  }
  return null
}

function WrightMenus({
  type,
  isLoading,
  loggedIn,
  isMembership,
  onClickLogout,
  isMobile,
  showMobileMenu,
  goBack
}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const { photo, nickname } = useUserInfo()
  const dispatch = useDispatch()

  const clickListener = () => {
    if (showDropdown) {
      setShowDropdown(false)
      document.removeEventListener("click", clickListener)
    }
  }
  useEffect(() => {
    return () => {
      document.removeEventListener("click", clickListener)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showDropdown) {
      logUserEvent("show_dropdown")
      document.addEventListener("click", clickListener)
    }
  }, [showDropdown]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isMobile) {
    if (type === TYPE.BACK) {
      return <div id="gnb__right" />
    }
    return (
      <div id="gnb__right" className="gnb__right--mobile">
        <MenuImg onClick={showMobileMenu} />
      </div>
    )
  }

  if ((!type || type !== TYPE.BACK) && loggedIn) {
    type = TYPE.MEMBER
  }

  const onClickDropdown = id => {
    switch (id) {
      case "mypage":
        break
      case "logout":
        onClickLogout()
        break
      default:
        break
    }
    setShowDropdown(false)
  }

  const { pathname } = window.location

  switch (type) {
    case TYPE.CLOSE:
      return (
        <div id="gnb__right" className="gnb__right--close">
          <CircleCloseImg
            onClick={goBack}
            width={window.innerWidth <= 800 ? 24 : 40}
          />
        </div>
      )
    case TYPE.MEMBER:
      return (
        <div id="gnb__right">
          <Link to="/templates">
            <TextMenu current={pathname === "/templates"}>템플릿</TextMenu>
          </Link>
          <Link to="/about">
            <TextMenu current={pathname === "/about"}>서비스 소개</TextMenu>
          </Link>
          <Link
            to="/write"
            onClick={() => logUserEvent("click_add", { location: "gnb" })}
          >
            <TextButtonMenu>템플릿 등록하기</TextButtonMenu>
          </Link>
          <div className="relative">
            <ProfilePicture
              className="photo"
              src={photo}
              onClick={() => setShowDropdown(true)}
              size={"3rem"}
            />
            {isMembership && (
              <BadgeImg
                title="뭐라고할까 멤버십"
                className="absolute -right-1 -top-1"
              />
            )}
          </div>
          {showDropdown && (
            <Dropdown onClick={({ target }) => onClickDropdown(target.id)}>
              <div id="nick">
                <b>{nickname}</b>님
              </div>
              <Link to="/my">
                <div id="my">마이프로필</div>
              </Link>
              <div id="logout" title="ㅠㅠ">
                로그아웃
              </div>
            </Dropdown>
          )}
        </div>
      )
    default:
      return (
        <div id="gnb__right">
          <Link to="/templates">
            <TextMenu current={pathname === "/templates"}>템플릿</TextMenu>
          </Link>
          <Link to="/about">
            <TextMenu current={pathname === "/about"}>서비스 소개</TextMenu>
          </Link>
          {!isLoading && (
            <TextButtonMenu onClick={() => dispatch(showLoginPopup())}>
              로그인
            </TextButtonMenu>
          )}
        </div>
      )
  }
}

function MobileMenu({
  isLoading,
  loggedIn,
  isMembership,
  hideMenu,
  photo,
  nickname,
  onClickLogout
}) {
  const history = useHistory()
  const dispatch = useDispatch()

  const onClick = e => {
    e.stopPropagation()
    const { target } = e
    hideMenu()
    switch (target.id) {
      case "intro":
        history.push("/#feature")
        break
      case "templates":
      case "community":
      case "my":
      case "about":
        history.push("/" + target.id)
        break
      case "write":
        history.push("/" + target.id)
        logUserEvent("click_add", { location: "mobile menu" })
        break
      case "share":
        share()
        logUserEvent("share", { location: "mobile menu" })
        break
      case "logout":
        onClickLogout()
        break
      default:
        break
    }
  }

  return (
    <MobileMenuContainer>
      <Header>
        <div className="close" onClick={hideMenu}>
          <CloseImg fill="white" />
        </div>
        <Profile>
          {loggedIn ? (
            <div onClick={() => history.push("/my")} className="clickable">
              <div className="relative">
                <ProfilePicture className="photo" src={photo} size={"3rem"} />
                {isMembership && (
                  <BadgeImg
                    title="뭐라고할까 멤버십"
                    className="absolute right-3 -top-1"
                  />
                )}
              </div>
              <div>
                <p id="nick">
                  <b>{nickname}</b>님
                </p>
              </div>
            </div>
          ) : (
            <p>{isLoading ? "로그인 정보 확인 중" : "로그인이 필요합니다."}</p>
          )}
        </Profile>
      </Header>
      <Body>
        <Menu onClick={onClick}>
          {loggedIn && (
            <MenuItem id="my">
              <div>마이프로필</div>
            </MenuItem>
          )}
          <MenuItem id="templates">템플릿 전체보기</MenuItem>
          <MenuItem id="about">서비스 소개</MenuItem>
          <MenuItem id="community">커뮤니티</MenuItem>
          <MenuItem id="share">사이트 공유하기</MenuItem>
          {loggedIn && (
            <RegisterButton id="write">템플릿 등록해볼래요</RegisterButton>
          )}
        </Menu>
        {loggedIn ? (
          <div id="logout" onClick={onClick}>
            로그아웃
          </div>
        ) : !isLoading ? (
          <LoginFooter>
            <div onClick={() => dispatch(showLoginPopup())}>로그인</div>
          </LoginFooter>
        ) : null}
      </Body>
    </MobileMenuContainer>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  height: var(--gnb-height);
  padding: 0 150px;
  background-color: ${props => (props.clear ? "transparent" : "#5D5FEF")};
  font-size: 1rem;
  color: #ffffff;
  text-align: right;
  transition: 0.5s;
  #logo {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  #gnb__left,
  #gnb__right {
    display: flex;
    align-items: center;
    > * {
      cursor: pointer;
    }
    .photo {
      margin-left: 32px;
    }
  }
  #gnb__right {
    position: relative;
    #update-dot {
      position: absolute;
      right: 0;
      top: 16px;
      width: 5px;
      height: 5px;
      background: #3ee264;
      border-radius: 50%;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 32px 0 24px;
  }
  @media (max-width: 800px) {
    height: var(--top-padding);
    padding: var(--status-height) 24px 0;
    background-color: ${props => (props.clear ? "transparent" : "#5D5FEF")};
    #gnb__left,
    #gnb__right {
      min-width: 24px;
    }
  }
`
const TextMenu = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 32px;
  cursor: ${({ current }) => (current ? "default" : "pointer")};
  position: relative;
  ::before {
    content: "";
    display: ${({ current }) => (current ? "inline-block" : "none")};
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    top: -10px;
    left: calc(50% - 2px);
  }
  svg {
    margin-left: 4px;
  }
`
const TextButtonMenu = styled(TextMenu)`
  border: 1px solid #eaeaea;
  border-radius: 30px;
  font-size: 14px;
  line-height: 100%;
  padding: 12px 16px;
`
const Dropdown = styled.div`
  position: absolute;
  top: 75px;
  right: 0;
  width: 200px;
  padding-top: 9px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  text-align: left;
  filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.08));
  div {
    line-height: 3rem;
    font-size: 1rem;
    padding: 0 24px;
    color: black;
    border-top: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
    }
  }
  #nick {
    border-top: none;
    cursor: default;
  }
  @media (max-width: 800px) {
    display: none;
  }
`
const MobileMenuContainer = styled.div`
  position: fixed;
  left: 0;
  top: var(--status-height);
  width: 100%;
  height: calc(100% - var(--status-height));
  background: #ffffff;
  @media (min-width: 801px) {
    display: none;
  }
  --header-height: 144px;
`
const Header = styled.div`
  position: relative;
  height: var(--header-height);
  background: #5d5fef;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    cursor: pointer;
    svg {
      position: absolute;
      top: 30.5px;
      right: 30.5px;
    }
  }
`
const Profile = styled.div`
  position: absolute;
  left: 24px;
  bottom: 24px;
  > p {
    font-size: 1rem;
    line-height: 48px;
  }
  .photo {
    margin-left: 0;
    margin-right: 1rem;
  }
  > div {
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      #nick {
        display: flex;
        align-items: center;
      }
    }
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - var(--header-height));
  #logout {
    font-size: 14px;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
    color: #bdbdbd;
    border-top: 1px solid #eeeeee;
  }
`
const Menu = styled.div`
  color: #333333;
  text-align: left;
`
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  border-bottom: 1px solid #eeeeee;
  &#community,
  &#my {
    > div {
      display: flex;
      align-items: center;
      pointer-events: none;
      svg {
        margin-left: 8px;
      }
    }
  }
`
const RegisterButton = styled.div`
  height: 44px;
  margin: 1rem 24px;
  line-height: 44px;
  background: #5551ff;
  border-radius: 23px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`
const LoginFooter = styled.div`
  padding-bottom: 40px;
  > div {
    height: 48px;
    margin: 0 24px;
    background: #5551ff;
    border-radius: 23px;
    font-weight: 400;
    font-size: 14px;
    line-height: 46px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
`

export default Gnb
