import React from "react"
import Counter from "./Counter"
import Subscribe from "./Subscribe"
import { useUserInfo } from "../hooks/useUserInfo"
import Situations from "./Situations"
import Share from "./Share"
import MembershipUsers from "./MembershipUsers"

function Body() {
  const { loggedIn } = useUserInfo()
  return (
    <div>
      <Situations />
      <Counter />
      <MembershipUsers />
      <Share />
      {!loggedIn && <Subscribe />}
    </div>
  )
}

export default Body
