import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"
import "firebase/analytics"

const config = {
  apiKey: "AIzaSyCxPGV-UxB4IzfrzHPU0ZfTnLBQF0avoiI",
  authDomain: "whattosay-48bb1.firebaseapp.com",
  databaseURL: "https://whattosay-48bb1.firebaseio.com",
  projectId: "whattosay-48bb1",
  storageBucket: "whattosay-48bb1.appspot.com",
  messagingSenderId: "270056301741",
  appId: "1:270056301741:web:170d1c263d0a0eb1b41224",
  measurementId: "G-G3PKHZDMRR"
}

const initializeFirebaseApp = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
}
initializeFirebaseApp()

const analytics = firebase.analytics()
const logUserEvent = (eventName, eventParams) => {
  if (localStorage.getItem("aType") !== "A") {
    analytics.logEvent(eventName, eventParams)
  }
}

const auth = firebase.auth()
auth.onIdTokenChanged(user => {
  analytics.setUserId(user?.uid)
  analytics.setUserProperties({ login: user != null })
})

const provider = new firebase.auth.GoogleAuthProvider()
// GoogleAuthProvider 클래스를 authentication 라이브러리에서 사용할 수 있도록 불러오는 코드.
provider.addScope("profile")
provider.setCustomParameters({ prompt: "select_account" })
// signIn이랑 authentication을 위해서 GoogleAuthProvider를 사용할 때마다 구글 팝업을 항상 띄우기를 원한다는 의미이다.
const signInWithGoogle = () => {
  if (window.navigator.userAgent.includes("KAKAOTALK")) {
    window.alert(
      "죄송합니다. 카카오 브라우저에서는 구글 로그인이 지원되지 않습니다. 다른 브라우저를 이용해주세요."
    )
  } else {
    auth.signInWithPopup(provider)
  }
}
// signInWithPopup 메소드는 여러 파라미터를 받을 수 있다. 트위터, 페이스북, 깃허브 등으로도 로그인이 필요할 수도 있으므로.
// 여기에서는 google로 signIn할 것이기 때문에, 파라미터로 위에서 정의한 provider를 넣어준다.

const db = firebase.firestore()

const functions = firebase.functions()

const fieldValue = firebase.firestore.FieldValue
const fieldPath = firebase.firestore.FieldPath
const Timestamp = firebase.firestore.Timestamp

const storage = firebase.storage()

export {
  analytics,
  auth,
  db,
  storage,
  functions,
  fieldValue,
  fieldPath,
  signInWithGoogle,
  logUserEvent,
  Timestamp,
  initializeFirebaseApp
}

export default firebase
// 혹시 전체 라이브러리가 필요할지도 모르기 때문에 firebase도 export 해준다.
