import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import PageLayout from "../common/PageLayout"
import MasonryView from "../common/MasonryView"
import CommunityListView from "../community/CommunityListView"
import Empty from "../common/Empty"
import { db, logUserEvent } from "../common/firebase.utils"
import Tabs from "./Tabs"
import Profile from "./Profile"
import { ORDER, ORDER_BY } from "../common/constants"
import { useUserInfo } from "../hooks/useUserInfo"
import useIsMobile from "../hooks/useIsMobile"
import MembershipBar from "./MembershipBar"

export const TABS = { COPIED: "copied", MY: "my", COMMUNITY: "community" }

function My() {
  const [templates, setTemplates] = useState()
  const [posts, setPosts] = useState()
  const [currentTab, setCurrentTab] = useState(TABS.COPIED)

  const history = useHistory()
  const { userId, membershipFrom } = useUserInfo()
  const isMobile = useIsMobile()
  const isCommunityTab = currentTab === TABS.COMMUNITY

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  useEffect(() => {
    if (templates) setTemplates()
    if (userId) {
      if (currentTab === TABS.COPIED) {
        loadCopiedTemplates()
        logUserEvent("view_copied_templates")
      } else if (currentTab === TABS.MY) {
        loadMyTemplates()
        logUserEvent("view_my_templates")
      } else {
        loadMyPosts()
        logUserEvent("view_my_posts")
      }
    }
  }, [currentTab, userId]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadCopiedTemplates = async () => {
    const snapshot = await db
      .collection("copies")
      .where("userid", "==", userId)
      .get()
    const docCount = snapshot.size
    if (!docCount) {
      setTemplates([])
    } else {
      const _templates = []
      const templateIdSet = new Set()
      snapshot.docs.forEach(copyDoc => {
        const { templateid } = copyDoc.data()
        templateIdSet.add(templateid)
      })

      let i = 1
      for (const id of templateIdSet) {
        db.collection("templates")
          .doc(id)
          .get()
          .then(template => {
            if (template.exists) {
              let data = template.data()
              _templates.push({
                ...data,
                id
              })
            }
            if (i === templateIdSet.size) {
              setTemplates(_templates)
            }
            i++
          })
          .catch(function (error) {
            console.log("Error getting document:", error)
            i++
          })
      }
    }
  }

  const loadMyTemplates = async () => {
    const snapshot = await db
      .collection("templates")
      .where("writerid", "==", userId)
      .where("deletedat", "==", null)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.DESC)
      .get()
    const docCount = snapshot.size
    if (!docCount) {
      setTemplates([])
    } else {
      const _templates = []
      snapshot.docs.forEach(template => {
        let data = template.data()
        _templates.push({
          ...data,
          id: template.id
        })
      })
      setTemplates(_templates)
    }
  }

  const loadMyPosts = async () => {
    const snapshot = await db
      .collection("posts")
      .where("writerid", "==", userId)
      .where("deletedat", "==", null)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.DESC)
      .get()
    const docCount = snapshot.size
    if (!docCount) {
      setPosts([])
    } else {
      const _posts = []
      snapshot.docs.forEach(post => {
        let data = post.data()
        _posts.push({
          ...data,
          id: post.id
        })
      })
      setPosts(_posts)
    }
  }

  const onClickTab = ({ id }) => {
    setCurrentTab(id)
  }

  const onClickPost = post => {
    history.push(`/community/${post.category}/${post.id}`)
  }

  const EmptyView = () => {
    if (currentTab === TABS.COPIED) {
      return (
        <Empty
          text={`아직 복사한 템플릿이 없네요!\n템플릿의 복사 버튼을 눌러 편하게 이용하세요`}
          buttonText={"템플릿 둘러보기"}
          onClickButton={() => history.push("/templates")}
        />
      )
    }
    if (currentTab === TABS.MY) {
      return (
        <Empty
          text={`아직 작성한 템플릿이 없네요!\n유용한 템플릿이 있다면 공유 부탁드려요`}
          buttonText={"템플릿 등록할게요"}
          onClickButton={() => history.push("/write")}
        />
      )
    }
    return (
      <Empty
        text={`아직 작성한 커뮤니티 글이 없네요!\n커뮤니티에 참여해보세요:)`}
        buttonText={"글 쓰러 갈래요"}
        onClickButton={() => history.push("/community")}
      />
    )
  }

  return (
    <PageLayout>
      <Header>
        {isMobile && membershipFrom == null && (
          <MembershipBar userId={userId} membershipFrom={null} />
        )}
        <p className="short-desc">Profile</p>
        <p className="title">마이프로필</p>
      </Header>
      <Body>
        <div className="profile-and-tabs">
          <Profile />
          <Tabs
            onClick={({ target }) => onClickTab(target)}
            currentTab={currentTab}
          />
        </div>
        <ListContainer>
          {((isCommunityTab && posts?.length === 0) ||
            templates?.length === 0) && <EmptyView />}
          {isCommunityTab ? (
            <CommunityListView
              isLoading={!posts}
              posts={posts}
              onClickPost={p => onClickPost(p)}
              hideWriter={true}
            />
          ) : (
            <MasonryView
              isLoading={!templates}
              templates={templates}
              onSelectTag={() => {}}
              defaultColumns={2}
              gap="16px"
            />
          )}
        </ListContainer>
      </Body>
    </PageLayout>
  )
}

const Header = styled.div`
  height: 144px;
  margin-top: var(--gnb-height);
  padding-top: 48px;
  background: #fcfdff;
  text-align: center;
  .short-desc {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 16px;
    color: #5551ff;
  }
  .title {
    font-weight: 500;
    font-size: 32px;
  }
  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    margin-top: var(--gnb-height);
    border-bottom: 1px solid #ebebeb;
    padding-top: 0;
    padding-bottom: 24px;
    .short-desc {
      font-size: 12px;
      padding-top: 24px;
      margin-bottom: 8px;
      line-height: 1;
    }
    .title {
      font-size: 18px;
      line-height: 1;
    }
  }
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1138px;
  min-height: 55vh;
  margin: 37px auto;
  padding-left: 24px;
  padding-right: 24px;
  .profile-and-tabs {
    margin-right: 16px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    margin-top: 0;
    .profile-and-tabs {
      margin-right: 0;
    }
  }
`

const ListContainer = styled.div`
  width: 100%;
  max-width: 744px;
`

export default My
