import React, { useEffect, useState, useRef } from "react"

const MarqueeSlider = ({ children, speed = 50, gap = 16 }) => {
  const [translate, setTranslate] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const containerRef = useRef(null)
  const contentRef = useRef(null)

  // Calculate actual content width of one set of children
  useEffect(() => {
    if (contentRef.current) {
      const childNodes = Array.from(contentRef.current.children)
      const oneSetCount = childNodes.length / 3 // we're rendering 3 sets

      // Calculate width including the last gap
      const oneSetWidth = childNodes
        .slice(0, oneSetCount)
        .reduce((total, child, index, array) => {
          // Add child width
          total += child.offsetWidth
          // Add gap if not the last item
          if (index < array.length - 1) {
            total += gap
          }
          return total
        }, 0)

      setContentWidth(oneSetWidth)
    }
  }, [children, gap])

  // Animation loop
  useEffect(() => {
    let animationFrameId
    let lastTime = performance.now()

    const animate = currentTime => {
      const deltaTime = currentTime - lastTime
      lastTime = currentTime

      setTranslate(prevTranslate => {
        const newTranslate = prevTranslate - (speed * deltaTime) / 1000

        // Reset position when one set is fully scrolled
        // Add a small buffer (1px) to ensure smooth transition
        if (-newTranslate >= contentWidth + 1) {
          return 0
        }
        return newTranslate
      })

      animationFrameId = requestAnimationFrame(animate)
    }

    if (contentWidth > 0) {
      animationFrameId = requestAnimationFrame(animate)
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    }
  }, [speed, contentWidth])

  // Add responsive speed calculation
  const getResponsiveSpeed = () => {
    if (!containerRef.current) return speed
    const baseWidth = 1920
    const currentWidth = containerRef.current.offsetWidth
    const speedMultiplier = Math.min(Math.max(baseWidth / currentWidth, 1), 1.5)
    return speed * speedMultiplier
  }

  return (
    <div className="w-full">
      <div ref={containerRef} className="relative overflow-hidden pb-4 -mb-4">
        <div
          ref={contentRef}
          className="inline-flex items-center"
          style={{
            transform: `translateX(${translate}px)`,
            gap: `${gap}px`
          }}
        >
          {children}
          {children}
          {children}
        </div>
      </div>
    </div>
  )
}

export default MarqueeSlider
