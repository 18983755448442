import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { db, logUserEvent } from "../common/firebase.utils"
import TemplatesView from "./TemplatesView"
import {
  ORDER_BY,
  ORDER,
  PUBLIC_LIMIT_COUNT_SITUATION,
  PUBLIC_LIMIT_COUNT_ALL,
  LENGTH
} from "../common/constants"
import QueryString from "query-string-for-all"
import { useUserInfo } from "../hooks/useUserInfo"
import { isLimited, requestPay } from "../common/utils"
import { showLoginPopup } from "../store/slices/layerSlice"
import { useDispatch } from "react-redux"
import algoliasearch from "algoliasearch/lite"
import FilterPopup from "../common/FilterPopup"

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
)

function Templates() {
  const [isLoading, setIsLoading] = useState(false)
  const [gettingMore, setGettingMore] = useState(false)
  const [noMoreToLoad, setNoMoreToLoad] = useState(false)
  const [templates, setTemplates] = useState([])
  const [showFilterPopup, setShowFilterPopup] = useState(false)

  const [options, setOptions] = useState({})

  const lastVisible = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    isLoading: isLoadingLogin,
    loggedIn,
    unlimitedUntil,
    membership,
    userId
  } = useUserInfo()

  const isLimitedUser = membership === "default" && isLimited(unlimitedUntil)
  const BLUR_COUNT = 6
  const LIMITED_COUNT = options.situation
    ? PUBLIC_LIMIT_COUNT_SITUATION
    : PUBLIC_LIMIT_COUNT_ALL
  const LOAD_COUNT = isLimitedUser ? LIMITED_COUNT + BLUR_COUNT : 9
  const DEFAULT_ORDER_BY = ORDER_BY.CREATED_AT
  const DEFAULT_ORDER = ORDER.DESC

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  useEffect(() => {
    const _options = QueryString.parse(location.search)
    setOptions(_options)
  }, [location.search])

  useEffect(() => {
    loadTemplates()
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, [templates]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadTemplates = async isGettingMore => {
    const {
      tag,
      language,
      orderby = DEFAULT_ORDER_BY,
      order = DEFAULT_ORDER,
      situation,
      receiver,
      length
    } = options

    if (tag) {
      loadTemplatesWithTag(tag)
      return
    }

    let query = db.collection("templates").where("deletedat", "==", null)
    if (language) {
      if (language === "etc") {
        query = query.orderBy("language")
        query = query.where("language", "not-in", ["kor", "eng"])
      } else {
        query = query.where("language", "==", language)
      }
    }
    if (situation) {
      query = query.where("situation", "==", situation)
    }
    if (receiver) {
      query = query.where("receiver", "==", receiver)
    }
    if (length) {
      query = query
        .where("length", length === LENGTH.LONG ? ">=" : "<", 300)
        .orderBy("length", length === LENGTH.LONG ? "desc" : "asc")
    }
    query = query.orderBy(orderby, order)
    if (isGettingMore) {
      query = query.startAfter(lastVisible.current)
    } else {
      setIsLoading(true)
      logSearchEvent()
    }

    query
      .limit(LOAD_COUNT)
      .get()
      .then(snapshot => {
        let _templates = isGettingMore ? [...templates] : []
        let data = null
        snapshot.docs.forEach(doc => {
          data = doc.data()
          _templates.push({
            ...data,
            id: doc.id
          })
          lastVisible.current = doc
        })
        setNoMoreToLoad(snapshot.size < LOAD_COUNT)

        if (isGettingMore) {
          setGettingMore(false)
        }
        setTemplates(_templates)
      })
  }

  const logSearchEvent = () => {
    const params = {}
    const { situation, language, receiver } = options
    if (situation) {
      params.search_term = situation
    }
    if (language) {
      params.filter_language = language
    }
    if (receiver) {
      params.filter_receiver = receiver
    }
    logUserEvent("search", params)
  }

  const loadTemplatesWithTag = async tag => {
    setIsLoading(true)
    setNoMoreToLoad(true)
    const snapshot = await db.collection("tags").where("title", "==", tag).get()
    const templateCount = snapshot.size
    if (!templateCount) {
      setTemplates([])
    } else {
      const _templates = []
      snapshot.docs.forEach((tagDoc, tagIndex) => {
        let query = db.collection("templates").doc(tagDoc.data().templateid)
        query
          .get()
          .then(template => {
            if (template.exists) {
              let data = template.data()
              if (!data.deletedat) {
                if (
                  !options.language ||
                  options.language === data.language ||
                  (options.language === "etc" &&
                    !["kor", "eng"].includes(data.language))
                ) {
                  _templates.push({
                    ...data,
                    id: template.id
                  })
                }
              }
            }
            if (tagIndex === templateCount - 1) {
              setTemplates(_templates)
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error)
          })
      })
    }
  }

  const loadMore = () => {
    setGettingMore(true)
    loadTemplates(true)
  }

  const showAll = () => {
    updateSearchParams({})
  }

  const onFilterClick = () => {
    setShowFilterPopup(true)
    logUserEvent("click_filter")
  }

  const onApplyFilter = filter => {
    if (filter) {
      updateSearchParams({
        ...options,
        language: filter.language,
        receiver: filter.receiver,
        length: filter.length
      })
    }
    setShowFilterPopup(false)
  }

  const setLanguage = lang => {
    updateSearchParams({
      ...options,
      language: lang === options.language ? undefined : lang
    })
  }

  const setOrderBy = field => {
    let _orderby = options.orderby || DEFAULT_ORDER_BY
    let _order = options.order || DEFAULT_ORDER
    if (field === ORDER_BY.LAST_COPIED_AT && field === _orderby) {
      // chainging to desc order is not supported for this order.
      return
    }
    if (field === _orderby) {
      _order = _order === ORDER.ASC ? ORDER.DESC : ORDER.ASC
    } else {
      _orderby = field
      _order = ORDER.DESC
    }
    updateSearchParams({
      ...options,
      orderby: _orderby,
      order: _order
    })
    logUserEvent("click_orderby", {
      value: field
    })
  }

  const setSituation = situation => {
    if (situation) {
      updateSearchParams({ ...options, tag: undefined, situation })
    } else {
      showAll()
    }
  }

  const setTag = tag => {
    updateSearchParams({ language: options.language, tag })
  }

  const updateSearchParams = searchParams => {
    history.push("?" + QueryString.stringify(searchParams))
  }

  const onClickRequestTemplate = () => {
    logUserEvent("click_request_on_empty_view")
    history.push(
      loggedIn ? "/community/templates/write" : "/community/templates"
    )
  }

  const onClickWriteToUnlimitButton = () => {
    logUserEvent("click_write_to_unlock")
    if (loggedIn) {
      history.push("/write")
    } else {
      dispatch(
        showLoginPopup({
          message: "환영합니다!\n간편하게 로그인하고 템플릿을 등록하세요.",
          redirect: "/write"
        })
      )
    }
  }

  const onClickJoinMembership = () => {
    logUserEvent("click_join_on_templates_top")
    if (loggedIn) {
      requestPay(userId)
    } else {
      dispatch(
        showLoginPopup({
          message: "환영합니다!\n간편하게 로그인하고 멤버십을 가입하세요."
        })
      )
    }
  }

  return (
    <>
      <TemplatesView
        options={options}
        searchClient={searchClient}
        onSelectSituation={setSituation}
        onSelectLanguage={setLanguage}
        onSelectOrderBy={setOrderBy}
        onFilterClick={onFilterClick}
        isLoading={isLoading}
        gettingMore={gettingMore}
        noMoreToLoad={isLimitedUser || noMoreToLoad}
        isLoadingLogin={isLoadingLogin}
        isLimited={isLimitedUser}
        membership={membership}
        blurFrom={LIMITED_COUNT}
        templates={templates}
        loadMore={loadMore}
        onSelectTag={setTag}
        onClickRequestTemplate={onClickRequestTemplate}
        onClickWriteToUnlimitButton={onClickWriteToUnlimitButton}
        onClickJoinMembership={onClickJoinMembership}
      />
      {showFilterPopup && (
        <FilterPopup
          currentFilters={{
            language: options.language,
            receiver: options.receiver,
            length: options.length
          }}
          membership={membership}
          onApply={onApplyFilter}
        />
      )}
    </>
  )
}
export default Templates
