import React, { useEffect, useState } from "react"
import { db } from "../common/firebase.utils"
import MarqueeSlider from "../common/MarqueeSlider"
import ProfilePicture from "../common/ProfilePicture"

export default function MembershipUsers() {
  const [membershipUsers, setMembershipUsers] = useState()

  useEffect(() => {
    db.collection("users")
      .where("isMembership", "==", true)
      .get()
      .then(snapshot => {
        const users = []
        const shuffled = snapshot.docs
          .sort(() => Math.random() - 0.5)
          .filter(user => user.data().nickname !== "힘찬 실파")
        shuffled.forEach(doc => {
          const { nickname, photo } = doc.data()
          users.push({ nickname, photo })
        })
        setMembershipUsers(users)
      })
  }, [])

  if (!membershipUsers) return null

  return (
    <div className="py-[72px] md:py-[68.5px] bg-[#F7F7FF] text-center">
      <div className="font-semibold md:font-medium text-[#181433] text-base md:text-2xl leading-[120%] mb-[32px] md:mb-[42px]">
        멤버십에 가입해서 더 편하게 이용하세요 👋
      </div>
      <MarqueeSlider speed={40} gap={40}>
        {membershipUsers.map((user, index) => (
          <UserCard key={index} user={user} />
        ))}
      </MarqueeSlider>
    </div>
  )
}

const UserCard = ({ user }) => {
  return (
    <div
      className="font-medium flex-shrink-0 flex items-center justify-center bg-white py-8 px-10 rounded-xl"
      style={{ boxShadow: "0px 8px 8px 0px rgba(5, 7, 66, 0.06)" }}
    >
      <ProfilePicture src={user.photo} size="3rem" className="mr-[10px]" />
      <span className="shrink-0">{user.nickname}</span>
      <span className="text-[#8E98A4]">님</span>
    </div>
  )
}
