import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { db } from "../common/firebase.utils"
import { isInViewPort } from "../common/utils"
import CountUp from "react-countup"

const formatToCompactNumber = number => {
  if (!number) return ""
  const formatter = Intl.NumberFormat("en", { notation: "compact" })
  return formatter.format(number)
}

function Counter() {
  const counterRef = useRef()
  const [stats, setStats] = useState()
  const [isVisible, setIsVisible] = useState(!isInViewPort(counterRef.current))

  useEffect(() => {
    const getStats = () => {
      db.collection("statistics")
        .where("__name__", "in", ["actions", "templates"])
        .get()
        .then(snapshot => {
          const _stats = {}
          snapshot.docs.forEach(doc => {
            if (doc.id === "actions") {
              const { visit, copy } = doc.data()
              if (visit === 1) {
                getStats()
                return
              }
              _stats.visitor = visit
              _stats.copied = copy
            } else if (doc.id === "templates") {
              _stats.templates = doc.data().count
            }
          })
          setStats(_stats)
        })
    }

    setTimeout(getStats, 500)

    const checkScroll = () => {
      if (isVisible) {
        window.removeEventListener("scroll", checkScroll)
      } else if (isInViewPort(counterRef.current)) {
        setIsVisible(true)
        window.removeEventListener("scroll", checkScroll)
      }
    }
    window.addEventListener("scroll", checkScroll)
    return () => {
      window.removeEventListener("scroll", checkScroll)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const startCounter = isVisible && stats

  return (
    <Container ref={counterRef}>
      <div>
        <p>지구 곳곳에서</p>
        <div>
          <span>{formatToCompactNumber(stats?.visitor)}</span>
          <span>회</span>
        </div>
        <p>방문해 주셨어요</p>
      </div>
      <div className="relative">
        <div className="absolute top-4 right-1/2 translate-x-1/2 md:transform-none md:right-[18px] bg-primary rounded-[4px] py-[6px] px-[11px] text-white leading-none">
          보유량 우주 1위!
        </div>
        <div className="mt-6 md:mt-0">
          <CountNumber
            startCounter={startCounter}
            startNumber={stats?.templates / 2}
            endNumber={stats?.templates}
          />
          <span>개</span>
        </div>
        <p>템플릿 보유 중이에요</p>
      </div>
      <div>
        <div>
          <CountNumber
            startCounter={startCounter}
            startNumber={stats?.copied / 2}
            endNumber={stats?.copied}
          />
          <span>회</span>
        </div>
        <p>템플릿이 사용 되었어요</p>
      </div>
    </Container>
  )
}

function CountNumber({ startCounter, endNumber }) {
  return (
    <span>
      {startCounter && endNumber && (
        <CountUp
          start={0}
          end={endNumber}
          duration={1}
          formattingFn={n => Number(n).toLocaleString()}
        />
      )}
    </span>
  )
}

const Container = styled.div`
  text-align: center;
  display: flex;
  gap: 46px;
  height: 208px;
  margin: 40px auto 64px;
  justify-content: center;
  > div {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    border-radius: 10px;
    border: 1px solid #dee1e6;
    background: #f6f5f4;
    > p {
      color: #8e98a4;
      font-size: 21px;
      text-align: center;
      line-height: 140%;
    }
    > div {
      > span:first-child {
        font-family: "Space Grotesk", sans-serif;
        font-size: 60px;
        font-weight: 700;
        line-height: 100%;
        color: #181433;
      }
      > span:last-child {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 800px) {
    margin: 24px auto 48px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: auto;
    > div {
      padding: 32px 0;
      border-radius: 28px;
      > div {
        > span:first-child {
          font-size: 40px;
        }
        > span:last-child {
          font-size: 21px;
        }
      }
    }
  }
`

export default Counter
