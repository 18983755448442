import React, { useEffect } from "react"
import Gnb, { TYPE } from "../common/Gnb"
import styled from "styled-components"
import useToast from "../hooks/useToast"
import successImage from "../images/pay-success.png"
import thankImgSrc from "../images/icon-thank.svg"
import { useDispatch } from "react-redux"
import { useUserInfo } from "../hooks/useUserInfo"
import { logUserEvent } from "../common/firebase.utils"
import { update } from "../store/slices/userSlice"
import moment from "moment"

export default function PaymentSuccess() {
  const toast = useToast()
  const dispatch = useDispatch()
  const { userId, membershipFrom } = useUserInfo()

  useEffect(() => {
    if (userId && !membershipFrom) {
      const searchParams = new URLSearchParams(window.location.search)
      const orderId = searchParams.get("orderId")
      const paidAt = searchParams.get("paidAt")

      // If it's a payment redirect
      if (orderId && paidAt) {
        if (orderId.split("_")[0] === userId) {
          logUserEvent("purchase", {
            transaction_id: orderId,
            value: 5900,
            currency: "KRW",
            items: [
              {
                item_id: "membership",
                item_name: "뭐라고할까 멤버십",
                price: 5900,
                quantity: 1
              }
            ]
          })

          toast.showWithArrow({
            iconSrc: thankImgSrc,
            text: "멤버십 가입이 완료되었습니다.",
            pushLocation: `/my`,
            actionText: "마이페이지에서 확인하기",
            actionTextShort: "마이페이지",
            duration: 60000,
            hideActionOnMobile: false
          })

          dispatch(
            update({
              membership: "member",
              membershipFrom: moment(paidAt).format("YYYY년 MM월 DD일")
            })
          )
        }
      }
    }
  }, [userId, membershipFrom, dispatch])

  return (
    <div className="center">
      <Gnb clearOnTop={false} type={TYPE.CLOSE} />
      <DoneView>
        <p className="font-bold text-[20px] md:text-[32px] leading-none">
          결제 완료
        </p>
        <p className="mt-6 md:mb-6 mb-10 leading-5">
          뭐라고할까 멤버십에 가입해 주셔서 감사합니다.
          <br />
          이제부터 제한 없이 템플릿을 이용하세요!
        </p>
        <div className="mx-auto w-[200px] h-[200px] py-12 rounded-full">
          <img
            className="w-[75.38px] h-[102.6px] mx-auto"
            src={successImage}
            alt="success"
          />
        </div>
      </DoneView>
    </div>
  )
}

const DoneView = styled.div`
  text-align: center;
  div {
    background: linear-gradient(180deg, #f6f5fc 0%, #e7e3fd 100%);
  }
  > p {
    text-align: center;
    color: #333333;
  }
`
